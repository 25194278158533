<template>
  <v-sheet>
    <v-sheet class="pa-4" id="jsonEditor" ref="jsonEditorContainer" style="height: 400px;">
    </v-sheet>
    <v-sheet class="d-flex">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        small
        class="mr-5"
        color="primary"
        @click="saveConfig"
      >
        Save
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<script>
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
    const container = document.querySelector('#jsonEditor')

    const options = { 
      mode: 'code',
      onChange: this.onChange
    }

    this.jsonEditor = new JSONEditor(container, options)
    
    this.initConfig()
  },
  data: () => ({
    jsonEditor: null
  }),
  methods: {
    ...mapActions('admin', [
      'updateLeague'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    onChange() {
      this.json = this.jsonEditor.get()
    },
    getEditedConfig() {
      this.config = this.jsonEditor.get()
    },
    saveConfig() {
      this.updateLeague({
        configuration: this.json,
        id: this.$route.params.league_id
      })
      .then(() => {
        this.success('config updated')
      })
      .catch(e => {
        this.error(e)
      })
    },
    initConfig() {
      const string_config = this.leagueById(this.$route.params.league_id)?.configuration
      const json_config = string_config ? JSON.parse(string_config) : null

      if(!json_config) return

      this.jsonEditor.set(json_config)
      this.json = json_config
    }
  },
  computed: {
    ...mapGetters('admin', [
      'league',
      'leagues',
      'leagueById'
    ])
  },
  'watch': {
    'leagues': {
      handler() {
        this.initConfig()
      }
    }
  }
}
</script>